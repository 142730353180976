<template>
  <NuxtPage />
</template>

<script setup>

import { useI18n } from "vue-i18n";
const { t } = useI18n();

useHead({
  title: "CLD Distribution - Leading European Distributor and Wholesaler in Entertainment and Pop-Culture ",
  meta: [{ name: "description", content: t('cld_desc1') }],
});

</script>
